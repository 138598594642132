export interface Model {
    name: string
    rarity: string
    description: string
    orientation: string | null
    poster: string
    model: string | null
}

export const models = {
    sapphireOre: {
        name: 'Genesis - Sapphire Ore',
        rarity: 'Genesis - Rare',
        description: 'Blue, transparent jewel ore. It can be used to refine assets.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/ore_sapphire.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/ore_sapphire.glb',
    },
    sapphireGemstone: {
        name: 'Genesis - Sapphire Gemstone',
        rarity: 'Genesis - Unique',
        description: 'Gemstone that is filled with mythical powers from the Kriaz island.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/gemstone_sapphire.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/gemstone_sapphire.glb',
    },
    sapphireCrystal: {
        name: 'Genesis - Sapphire Crystal',
        rarity: 'Genesis - Mythic',
        description: 'One of the most rare resources in the Solice metaverse.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/crystal_blue.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/crystal_blue.glb',
    },
    rubyCrystal: {
        name: 'Genesis - Ruby Crystal',
        rarity: 'Genesis - Mythic',
        description: 'One of the most rare resources in the Solice metaverse.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/crystal_red.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/crystal_red.glb',
    },
    greySlasher: {
        name: 'Grey Slasher',
        rarity: 'Genesis - Rare',
        description: 'One of the oldest Katar weapons that came from Ludivour island.',
        orientation: '0deg 75deg 100%',
        poster: '/images/models/tier_4_weapon.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_4_weapon.glb',
    },
    aquaOblivion: {
        name: 'Aqua Oblivion',
        rarity: 'Genesis - Epic',
        description: 'Ancient weapon that is powered by special energy of the water and gemstones from Kriaz island.',
        orientation: '0deg 75deg 100%',
        poster: '/images/models/tier_3_weapon.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_3_weapon.glb',
    },
    havoc: {
        name: 'Havoc',
        rarity: 'Genesis - Unique',
        description:
            'Havoc, the hammer of justice. Created by the finest craftsman before the world got taken over by technology.',
        orientation: '0deg 75deg 100%',
        poster: '/images/models/tier_2_weapon.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_2_weapon.glb',
    },
    vanguard: {
        name: 'Vanguard',
        rarity: 'Genesis - Mythic',
        description: 'The legendary sword of the gods that was seen from Zephyros island',
        orientation: '1.539380400259003rad 1.4974924982111364rad 9.433922151928353m',
        poster: '/images/models/tier_1_weapon.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_1_weapon.glb',
    },
    t4Building: {
        name: 'Building ZT-04',
        rarity: 'Rare',
        description: 'A building that can be used in the Solice metaverse.',
        orientation: '0.8838347332099281rad 1.4472270157536993rad 529.5892623970167m',
        poster: '/images/models/tier_4_building.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_4_building.glb',
    },
    t3Building: {
        name: 'Building ZT-03',
        rarity: 'Epic',
        description: 'A building that can be used in the Solice metaverse.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/tier_3_building.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_3_building.glb',
    },
    t2Building: {
        name: 'Building ZT-02',
        rarity: 'Unique',
        description: 'A building that can be used in the Solice metaverse.',
        orientation: '60deg 75deg 100%',
        poster: '/images/models/tier_2_building.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_2_building.glb',
    },
    t1Building: {
        name: 'Building ZT-01',
        rarity: 'Mythic',
        description: 'A building that can be used in the Solice metaverse.',
        orientation: '14.895337968220394rad 1.3969615332962684rad 1003.7141726316959m',
        poster: '/images/models/tier_1_building.png',
        model: 'https://storage.googleapis.com/cdn.solice.io/assets/models/tier_1_building.glb',
    },
    land3x3: {
        name: '3x3 Land',
        rarity: '-',
        description:
            'A land plot players can own in the metaverse. (The building and assets that you see do not come included with the land)',
        additional:
            'Each LAND parcel is represented by square(s). Each square(1x1) is 15 meters by 15 meters in size and each piece of LAND parcel is represented by an NFT (non-fungible token). \n\nLands comes in various sizes: 1x1, 3x3, 6x6, 9x9 and 12x12. \n\nWe will have first rounds of Land sales in Q1, follow us on socials to stay updated!',
        orientation: null,
        poster: '/images/models/land.png',
        model: '/images/models/land.png',
    },
}
