import styles from './CountDown.module.scss'

import React, { useEffect, useState } from 'react'
import classnames, { Value } from 'classnames'
import format from 'date-fns/format'
interface Props {
    className?: Value
    title: string
    message?: string
    releaseDate?: Date
    releaseMessage?: string
    releaseLink?: string
    showCountDown?: boolean
    infoMessage: React.ReactNode
}

const getTimeRemaining = (releaseDate?: Date, showCountDown?: boolean, releaseMessage = 'NOW') => {
    if (!releaseDate) {
        return
    }

    const t = releaseDate.getTime() - new Date().getTime()

    if (t <= 0) {
        return releaseMessage ?? `NOW`
    }

    if (!showCountDown) {
        return format(releaseDate, 'd LLLL')
    }

    const seconds = Math.floor((t / 1000) % 60)
        .toString()
        .padStart(2, '0')
    const minutes = Math.floor((t / 1000 / 60) % 60)
        .toString()
        .padStart(2, '0')
    const hours = Math.floor(t / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0')

    const timeString = `${hours}:${minutes}:${seconds}`

    return timeString
}

const CountDown: React.FC<Props> = ({
    className,
    title,
    releaseLink,
    releaseDate,
    message,
    showCountDown,
    releaseMessage,
    infoMessage,
}) => {
    const [time, setTime] = useState(getTimeRemaining(releaseDate, showCountDown, releaseMessage))

    useEffect(() => {
        if (!releaseDate) return
        const timer = window.setInterval(() => {
            setTime(getTimeRemaining(releaseDate, showCountDown, releaseMessage))
        }, 500)

        return () => window.clearInterval(timer)
    }, [releaseDate, releaseMessage, showCountDown])

    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.releaseTitle}>{title}</div>
            <div className={styles.countDown}>
                {message ? (
                    message
                ) : time === releaseMessage && releaseLink ? (
                    <a href={releaseLink}>{releaseMessage}</a>
                ) : (
                    time
                )}
            </div>
            <div className={styles.releaseDescription}>{infoMessage}</div>
        </div>
    )
}

export default CountDown
