/* eslint-disable @next/next/no-img-element */
import styles from './InvestorsBanner.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import LazyImage from '../LazyImage/LazyImage'

interface Props {
    className?: Value
}

const partnersList = [
    'genblock_logo',
    'solanium_logo',
    'alameda_logo',
    'cms_logo',
    'titans_logo',
    'gate_labs',
    'jump_logo',
    'kucoin_logo',
    'solar_logo',
    'svc_logo',
    'a41_logo',
    'maven_logo',
    'doublepeak_logo',
    'zbs_logo',
    'mintable_logo',
    'peech_logo',
    'icetea_logo',
    'skynet_logo',
    'dweb3_logo',
    'cropperbros_logo',
]

const InvestorsBanner: React.FC<Props> = ({ className }) => {
    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.banner}>
                <div className={styles.inner}>
                    <h3 className={styles.title}>Investors</h3>
                    <div className={styles.mainInvestors}>
                        <LazyImage className={styles.mainInvestorImage} src={'/images/3ac_logo.png'} />
                        <LazyImage className={styles.mainInvestorImage} src={'/images/defiance_logo.png'} />
                        <LazyImage className={styles.mainInvestorImage} src={'/images/animoca_logo.png'} />
                    </div>
                </div>
                <div className={styles.carousel}>
                    <div className={styles.slider}>
                        {[...partnersList, ...partnersList].map((img, index) => (
                            <div key={`${img}-${index}`} className={styles.carouselImage}>
                                <LazyImage src={`/images/${img}.png`} alt={img} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorsBanner
