import styles from './ScrollDownIndicator.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'

interface Props {
    className?: Value
}

const ScrollDownIndicator: React.FC<Props> = ({ className }) => {
    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.mouse}>
                <div className={styles.wheel}></div>
            </div>
            <div>
                <span className={styles.arrow1}></span>
                <span className={styles.arrow2}></span>
                <span className={styles.arrow3}></span>
            </div>
        </div>
    )
}

export default ScrollDownIndicator
