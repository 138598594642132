/* eslint-disable @next/next/no-img-element */
import styles from './NFTPreviewBanner.module.scss'

import React, { useState } from 'react'
import classnames, { Value } from 'classnames'
import dynamic from 'next/dynamic'
import { Model, models } from './models'
import LazyImage from '../LazyImage/LazyImage'

interface Props {
    className?: Value
}

const Viewer = dynamic(() => import('../ModelViewer/ModelViewer'), { ssr: false })

const NFTPreviewBanner: React.FC<Props> = ({ className }) => {
    const [selectedModel, setSelectedModel] = useState<Model>(models.havoc)

    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.inner}>
                <h3 className={styles.title}>NFT Previews</h3>
                <div>
                    <div className={styles.models}>
                        {Object.values(models).map((model) => (
                            <div className={styles.model} key={model.name} onClick={() => setSelectedModel(model)}>
                                <LazyImage src={model.poster} alt={model.description} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.modelViewer}>
                        <Viewer
                            src={selectedModel.model}
                            poster={selectedModel.poster}
                            orientation={selectedModel.orientation}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NFTPreviewBanner
