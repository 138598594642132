/* eslint-disable @next/next/no-img-element */
import styles from './Header.module.scss'

import React, { useCallback, useEffect, useState } from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'

interface Props {
    className?: Value
    floating?: boolean
}

const Header: React.FC<Props> = ({ className, floating }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const handleScroll = useCallback(() => {
        requestAnimationFrame(() => {
            if (!scrolled && window.scrollY >= 90) {
                setScrolled(true)
            }
            if (scrolled && window.scrollY < 90) {
                setScrolled(false)
            }
        })
    }, [scrolled])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll])

    return (
        <div
            className={classnames(styles.container, className, {
                [styles.isFloating]: floating,
                [styles.isOpen]: isOpen,
                [styles.isScrolled]: scrolled,
            })}
        >
            <div className={styles.logoContainer}>
                <Link href={'/'}>
                    <a className={styles.logo}>
                        <img src="/images/solice_logo_name_right_white.png" alt={'Solice Logo'} />
                    </a>
                </Link>
                <button type="button" className={styles.mobileButton} onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? 'CLOSE' : 'MENU'}
                </button>
            </div>
            <nav className={styles.nav}>
                <Link href={'/map'}>
                    <a className={styles.navLink}>Map</a>
                </Link>
                <Link href={'https://market.solice.io'}>
                    <a className={styles.navLink}>Marketplace</a>
                </Link>
                <Link href={'https://staking.solice.io'}>
                    <a className={styles.navLink}>Genesis Staking</a>
                </Link>
                <Link href={'https://portal.solice.io'}>
                    <a className={styles.navLink}>Staking</a>
                </Link>
                <WalletMultiButton />
            </nav>
        </div>
    )
}

export default Header
