/* eslint-disable @next/next/no-img-element */
import styles from './Press.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import LazyImage from '../LazyImage/LazyImage'

interface Props {
    className?: Value
}

const pressLogos = [
    'enzac_logo.png',
    'cointelegraph_press.png',
    'investing_press.png',
    'yahoo_press.png',
    'coindesk_press.png',
    'dailycoin_press.png',
    'odaily_press.png',
    'coinrivet_press.png',
    'cryptobriefing_press.png',
    'blockonomi_press.png',
    'cryptoadventure_press.png',
    '8848_press.png',
]

const Press: React.FC<Props> = ({ className }) => {
    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.inner}>
                <h3 className={styles.title}>Press</h3>
                <div className={styles.images}>
                    {pressLogos.map((logo) => (
                        <div key={logo}>
                            <LazyImage className={classnames(styles.pressLogo)} src={`/images/${logo}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Press
