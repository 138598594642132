import styles from './Button.module.scss'

import React, { useCallback, useState } from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'

interface Props {
    className?: Value
    type?: 'submit' | 'reset' | 'button'
    popover?: React.ReactNode
    href?: string
    disabled?: boolean
    onClick?: () => void
}

const Button: React.FC<Props> = ({ className, children, onClick, type = 'button', href, popover, disabled }) => {
    const [popoverActive, setPopoverActive] = useState(false)

    const sharedProps = {
        className: classnames(styles.container, className, {
            [styles.disabled]: !!disabled,
        }),
        onMouseEnter: () => {
            if (popover) setPopoverActive(true)
        },
        onMouseLeave: () => {
            if (popover) setPopoverActive(false)
        },
        onClick: onClick,
    }

    const renderInner = useCallback(
        () => (
            <>
                {popover && (
                    <div
                        className={classnames(styles.popover, {
                            [styles.active]: popoverActive,
                        })}
                    >
                        {popover}
                    </div>
                )}
                {children}
            </>
        ),
        [children, popover, popoverActive]
    )

    if (href) {
        if (disabled) {
            return <div {...sharedProps}>{renderInner()}</div>
        }

        return (
            <Link href={href}>
                <a {...sharedProps}>{renderInner()}</a>
            </Link>
        )
    }

    return (
        <button type={type} disabled={disabled} {...sharedProps}>
            {renderInner()}
        </button>
    )
}

export default Button
