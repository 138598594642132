import styles from './Banner.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import Button from '../Button/Button'

interface Props {
    className?: Value
    title: string
    description: string
    buttonHref?: string
    buttonlabel?: string
    buttonPopover?: string
    buttonDisabled?: boolean
    offsetText?: number
    image?: string
    align?: 'left' | 'right'
}

const Banner: React.FC<Props> = ({
    className,
    title,
    description,
    buttonHref,
    buttonPopover,
    buttonDisabled,
    buttonlabel,
    image,
    offsetText,
    align = 'right',
}) => {
    return (
        <div
            className={classnames(styles.container, className, {
                [styles.left]: align === 'left',
                [styles.right]: align === 'right',
            })}
        >
            <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
            <div className={styles.gradient} />
            <div className={styles.decoration} />
            <div className={styles.align}>
                <div className={styles.innerContainer}>
                    <h3 className={styles.title} style={{ marginTop: offsetText }}>
                        {title}
                    </h3>
                    <p className={styles.description}>{description}</p>
                    {buttonlabel && (
                        <Button
                            className={styles.button}
                            href={buttonHref}
                            disabled={buttonDisabled}
                            popover={buttonPopover}
                        >
                            {buttonlabel}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Banner
