import styles from './Hero.module.scss'

import React, { useEffect, useState } from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'
import Header from '../Header/Header'
import ScrollDownIndicator from '../ScrollDownIndicator/ScrollDownIndicator'
import CountDown from './CountDown'

interface Props {
    className?: Value
}

const releaseDate = new Date(1650978000000)

const Hero: React.FC<Props> = ({ className }) => {
    const [showSource, setShowSource] = useState(false)

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setShowSource(true)
        }, 100)

        return () => window.clearTimeout(timer)
    }, [])

    return (
        <div className={classnames(styles.container, className)}>
            <ScrollDownIndicator className={styles.indicator} />
            <video playsInline autoPlay loop muted className={styles.video} poster={'/images/poster.jpg'}>
                {showSource && (
                    <>
                        <source
                            src="https://storage.googleapis.com/cdn.solice.io/public/videos/splash_video1.webm"
                            type="video/webm"
                        />
                        <source
                            src="https://storage.googleapis.com/cdn.solice.io/public/videos/splash_video1.mp4"
                            type="video/mp4"
                        />
                    </>
                )}
            </video>
            <Header className={styles.header} />
            <div className={styles.inner}>
                <div>
                    <div className={styles.info}>
                        <div>
                            <h1 className={styles.title}>Welcome to the solice metaverse</h1>
                            <h2 className={styles.subTitle}>
                                The first cross platform VR metaverse on the Solana blockchain
                            </h2>
                        </div>
                        <div>
                            <CountDown
                                title="Land Auctions now Live"
                                releaseDate={releaseDate}
                                releaseMessage={'Bid now'}
                                releaseLink={'https://market.solice.io?tab=auction'}
                                showCountDown={true}
                                infoMessage={<>Place your bid now to grab one of the new 6x6 and 9x9 NFT lands.</>}
                            />
                        </div>
                    </div>
                    <div className={styles.socials}>
                        <Link href="https://twitter.com/solice_io">
                            <a>
                                <svg className={styles.twitter} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M24 4.557a9.83 9.83 0 0 1-2.828.775 4.932 4.932 0 0 0 2.165-2.724 9.864 9.864 0 0 1-3.127 1.195 4.916 4.916 0 0 0-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 0 1 1.671 3.149a4.93 4.93 0 0 0 1.523 6.574 4.903 4.903 0 0 1-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 0 1-2.224.084 4.928 4.928 0 0 0 4.6 3.419A9.9 9.9 0 0 1 0 19.54a13.94 13.94 0 0 0 7.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0 0 24 4.557z" />
                                </svg>
                            </a>
                        </Link>
                        <Link href="https://medium.com/@solice_io">
                            <a>
                                <svg
                                    className={styles.medium}
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                >
                                    <path d="M2.846 6.887a.928.928 0 0 0-.303-.784l-2.24-2.7V3h6.958l5.378 11.795L17.367 3H24v.403L22.084 5.24a.561.561 0 0 0-.213.538v13.498a.56.56 0 0 0 .213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537V8.321l-5.389 13.688h-.728L4.28 8.321v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404H0v-.404l2.521-3.058c.27-.279.39-.67.325-1.052V6.887z" />
                                </svg>
                            </a>
                        </Link>
                        <Link href="https://t.me/solice_io">
                            <a>
                                <svg
                                    className={styles.telegram}
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlSpace="preserve"
                                    style={{
                                        fillRule: 'evenodd',
                                        clipRule: 'evenodd',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 1.41421,
                                    }}
                                >
                                    <path
                                        fill="#000"
                                        d="M18.384 22.779a1.19 1.19 0 0 0 1.107.145 1.16 1.16 0 0 0 .724-.84C21.084 18 23.192 7.663 23.983 3.948a.78.78 0 0 0-.26-.758.8.8 0 0 0-.797-.14C18.733 4.602 5.82 9.447.542 11.4a.827.827 0 0 0-.542.799c.012.354.25.661.593.764 2.367.708 5.474 1.693 5.474 1.693s1.452 4.385 2.209 6.615c.095.28.314.5.603.576a.866.866 0 0 0 .811-.207l3.096-2.923s3.572 2.619 5.598 4.062Zm-11.01-8.677 1.679 5.538.373-3.507 10.185-9.186a.277.277 0 0 0 .033-.377.284.284 0 0 0-.376-.064L7.374 14.102Z"
                                    />
                                </svg>
                            </a>
                        </Link>
                        <Link href="https://discord.gg/solice">
                            <a>
                                <svg
                                    className={styles.discord}
                                    viewBox="0 0 71 55"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#a)">
                                        <path
                                            d="M60.105 4.898A58.55 58.55 0 0 0 45.653.415a.22.22 0 0 0-.233.11 40.784 40.784 0 0 0-1.8 3.697c-5.456-.817-10.886-.817-16.23 0-.485-1.164-1.201-2.587-1.828-3.697a.228.228 0 0 0-.233-.11 58.386 58.386 0 0 0-14.451 4.483.207.207 0 0 0-.095.082C1.578 18.73-.944 32.144.293 45.39a.244.244 0 0 0 .093.167c6.073 4.46 11.955 7.167 17.729 8.962a.23.23 0 0 0 .249-.082 42.08 42.08 0 0 0 3.627-5.9.225.225 0 0 0-.123-.312 38.772 38.772 0 0 1-5.539-2.64.228.228 0 0 1-.022-.378c.372-.279.744-.569 1.1-.862a.22.22 0 0 1 .23-.03c11.619 5.304 24.198 5.304 35.68 0a.219.219 0 0 1 .233.027c.356.293.728.586 1.103.865a.228.228 0 0 1-.02.378 36.384 36.384 0 0 1-5.54 2.637.227.227 0 0 0-.121.315 47.249 47.249 0 0 0 3.624 5.897.225.225 0 0 0 .249.084c5.801-1.794 11.684-4.502 17.757-8.961a.228.228 0 0 0 .092-.164c1.48-15.315-2.48-28.618-10.497-40.412a.18.18 0 0 0-.093-.084Zm-36.38 32.427c-3.497 0-6.38-3.211-6.38-7.156 0-3.944 2.827-7.156 6.38-7.156 3.583 0 6.438 3.24 6.382 7.156 0 3.945-2.827 7.156-6.381 7.156Zm23.593 0c-3.498 0-6.38-3.211-6.38-7.156 0-3.944 2.826-7.156 6.38-7.156 3.582 0 6.437 3.24 6.38 7.156 0 3.945-2.798 7.156-6.38 7.156Z"
                                            fill="#000"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="a">
                                            <path fill="#000" d="M0 0h71v55H0z" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
