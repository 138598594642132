import React from 'react'
import type { NextPage } from 'next'
import Hero from '../components/Hero/Hero'
import Banner from '../components/Banner/Banner'
import InvestorsBanner from '../components/InvestorsBanner/InvestorsBanner'
import NFTPreviewBanner from '../components/NFTPreviewBanner/NFTPreviewBanner'
import Head from 'next/head'
import Press from '../components/Press/Press'
// import CountDownBanner from '../components/CountDownBanner/CountDownBanner'

const Index: NextPage = () => {
    return (
        <>
            <Head>
                <title>Solice.io</title>
            </Head>
            <Hero />
            {/* <div className={styles.heroBanner}>
                <CountDownBanner />
            </div> */}
            <Banner
                title="Play"
                description="Explore the metaverse owned and built by users in an immersive way through Virtual Reality. Solice will contain social, gaming and crypto elements to make sure that everyone will be able to find a way to enjoy themselves within the metaverse."
                buttonlabel="Learn more"
                buttonHref="/play"
                align="right"
                image="/images/play.png"
            />
            <Banner
                title="Virtual reality"
                description="By enabling the Solice metaverse on VR, users will get a more immersive feeling to fully enjoy themselves within this virtual metaverse. While Solice is made to interact with VR, it is also available without a VR system."
                align="left"
                image="/images/virtual_reality.png"
            />
            <InvestorsBanner />
            <Banner
                title="Lands"
                description="Buy lands in one of our upcoming land sales and create your own paradise in Solice! Stay tuned for more details."
                buttonlabel="Check map"
                buttonHref="/map"
                align="right"
                image="/images/lands.png"
            />
            <NFTPreviewBanner />
            <Press />
        </>
    )
}

export default Index
